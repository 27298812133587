body {  
  font-family: "Montserrat", sans-serif;
}

.text-primary, .btn-primary {
  color: #1966c3 !important;
}

.bg-primary {
  background-color: #1966c3 !important;
}

#logo{
  width: 300px;
} 

.hero-section {
  background: url('./images/banner-image.jpg') no-repeat center center;
  background-size: cover;
  color: white;
}

.floating-tab {
  position: fixed;
  right: 0;
  bottom: 0; 
} 